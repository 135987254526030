import React, { useEffect, useState, useRef } from 'react';
import {
    Utils, WebSocket, DataEditor, DataEditorRow, Overlay, Table,
    saveDoc, getDataType, query, Spinner
} from './bpdbcrud.js';
import Instruction from './Instruction.js';

function UserList({setUserView})
{
    const PAGE_SIZE = 100;
    const [pattern, setPattern] = useState('');
    const [userList, setUserList] = useState(false);

    const easy = useRef({});
    const search = async (page) => {
        const list = await getUserList({
            page, pageSize: PAGE_SIZE, pattern
        });
        if (list.error) {
            return;
        }
        setUserList(list.extra);
        easy.current = list.extra.easy;
    };
    const columns = [
        'phone', 'total_purchase', 'total_consumption', 'inviter', 'lock'
    ];

    const resultTable = <Table
        resultList={userList}
        pageSize={PAGE_SIZE}
        attributes={easy.current.attributes}
        columns={columns}
        rowClicked={(user) => setUserView({
            ...user,
            ...easy.current,
            tempId: user.id
        })}
        search={search}
    />

    const createUser = async () => {
        const dataType = await getDataType('user');
        if (dataType.error) {
            Utils.tipOnTop(dataType.code);
            return;
        }

        setUserView({
            field: {},
            ...dataType.easy,
            tempId: Math.random()
        });
    };

    return <div className="namelist">
        <div className="search">
            <div className="w-100 d-flex gap-2 py-2">
                <button
                    className="btn btn-warning"
                    onClick={createUser}
                >
                    新建
                </button>
                <input
                    value={pattern}
                    onChange={e => setPattern(e.target.value)}
                />
                <button
                    className="btn btn-success"
                    onClick={() => search(1)}
                >检索</button>
            </div>
        </div>
        {userList.total ? resultTable : null}
    </div>;
}

function UserView(props)
{
    const {
        id, field, close, attributes, mustHave, optionalHave, updateUser
    } = props;
    const [userId, setUserId] = useState(id);

    const [overlay, setOverlay] = useState(false);

    const updateData = async (ret) => {
        if (ret.error) {
            return ret;
        }
        const one = JSON.parse(ret.extra);
        setUserId(one._id);
        for (const m in field) {
            delete field[m];
        }
        for (const m in one.properties) {
            field[m] = one.properties[m];
        }
        updateUser({
            id: one._id,
            ...props
        });
        return ret;
    };

    const instructionView = ({
        editable, sourceValues, valueChanged, changes, updateValues}) => {
        return <InstructionView
            {...{editable, sourceValues, updateValues, valueChanged}}
        />;
    };

    const listRelative = async ({dtName, title, page}) => {
        const list = await query(
            dtName,
            [{n: 'user', s: 'string', v: userId}],
            '{n: "last_modified_time", s: "int", a: false}',
            page,
            50,
            ''
        );
        if (list.error) {
            Utils.tipOnTop('检索出错');
            return;
        }
        const ho = () => setOverlay({
            component: resultTable,
            onClose: () => {
                setOverlay(false);
            },
            title: title
        });

        const resultTable = <Table
            resultList={list.extra}
            pageSize={50}
            attributes={list.extra.easy.attributes}
            rowClicked={(relative) => setOverlay({
                component: <DataEditor
                    dataTableName={dtName}
                    close={ho}
                    {...relative}
                    {...list.extra.easy}
                />,
                title: title
            })}
            search={(page = 1) => listRelative({
                dtName: dtName, page: page, title: title
            })}
        />
        ho();
    };

    const listHistory = (page = 1) => {
        listRelative({dtName: 'history', page: page, title: '历史'});
    };

    const listLogs = (page = 1) => {
        listRelative({dtName: 'log', page: page, title: '日志'});
    };

    const relatives = <div className="d-flex justify-content-between">
        <button className={`btn btn-info`} onClick={() => listHistory(1)}>
            历史
        </button>
        <button className={`btn btn-info`} onClick={() => listLogs(1)}>
            日志
        </button>
    </div>;

    const userEditor = <DataEditor {...props}
        updateData={updateData}
        dataTableName="user"
        extraField={instructionView}
    />;
    return <div className="user-view">
        {relatives}
        {userEditor}
        {overlay ?  <Overlay {...overlay}/> : null}
    </div>;
}

function InstructionView({
    editable, sourceValues, updateValues, valueChanged})
{
    const [instructionMap, setInstructionMap] = useState(false);
    const [values, setValues] = useState(sourceValues);
    if (!instructionMap) {
        Instruction.getInstructions(setInstructionMap);
        return <Spinner />;
    }

    const instr = instructionMap.get(values.instruction?.[0]);
    const onClick = (instr) => {
        values.instruction = [instr.id];
        valueChanged('instruction');
        setValues({...values});
    };
    return <div className="dataeditor-row instruction">
        <div className="left">
            {instr && Instruction.format(instr)}
        </div>
        <div className={`right${editable ? '' : ' d-none'}`}>
            <div className="instruction-caption">全部指令</div>
            <div className="instruction-content">
                {Instruction.list(instructionMap, instr?.id, onClick)}
            </div>
        </div>
    </div>;
}

/*
function ListViewComponent({
    defaults, userId, updateList, originalList, close, title
})
{
    const [list, setList] = useState(originalList);
    const [view, setView] = useState(false);
    const refresh = useRef(false);

    const newView = () => {
        setView({
            field: {...defaults}
        });
        refresh.current = true;
    }

    const save = async (id, values) => {
        const doc = await saveDoc(list.dataTableName, id, values);
        if (doc.error){
            return doc;
        }
        if (typeof updateList === 'function' && refresh.current) {
            setList(await updateList());
            refresh.current = false;
        }
        return doc;
    };

    const listElem = <>
        <div className="operations">
            <button
                className={`btn btn-primary ${updateList ? '' : 'invisible'}`}
                onClick={newView}
            >
                <span className="icon-add"/>
            </button>
            <button
                className="close-button"
                onClick={close}
            >
                <span className="icon-clear"/>
            </button>
        </div>
        <Table
            resultList={list}
            attributes={list.easy.attributes}
            pageSize={-1}
            rowClicked={(view) => setView(view)}
        />
    </>;
    const body = view ? <Overlay
        component={
            <DataEditor
                {...view}
                {...list.easy}
                close={() => setView(false)}
                save={save}
            />
        }
    /> : false;

    return <div className="orders">
        <div className="view-caption">{title}</div>
        {listElem}
        {body}
    </div>;
}
*/

async function getUserList({page = 1, pageSize, pattern, f2r}) {
    const criteria = [];
    if (pattern) {
        criteria.push(...[{
            "n":"(",
            "s":"logic",
        }, {
            "n":"phone",
            "s":"single_char",
            "v": pattern
        }, {
            "n":"|",
            "s":"logic",
        }, {
            "n":"accesscode",
            "s":"single_char",
            "v": pattern
        }, {
            "n":"|",
            "s":"logic",
        }, {
            "n":"inviter",
            "s":"single_char",
            "v": pattern
        }]);
    } else {
        criteria.push({
            n: 'created_time',
            s: 'int',
            f: 1,
            t: ''
        });
    }

    const list = await query(
        'user',
        criteria,
        '{n: "last_modified_time", s: "int", a: false}',
        page,
        pageSize || 0,
        f2r || '*'
    );
    if (list.error) {
        Utils.tipOnTop('检索出错');
    }
    return list;
}

export {UserView};
export default UserList;
